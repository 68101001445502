// in src/posts.js
import React from 'react'

import {
  Create,
  Datagrid,
  Edit,
  ImageField,
  List,
  maxLength,
  required,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput, minValue, maxValue,
  SelectField,
  NumberField,
  TabbedForm,
  FormTab,
  NumberInput,
  FileField,
  FileInput,
  ReferenceArrayField,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin'
import Divider from '@material-ui/core/Divider'
import { ListPagination } from '../Utilities/transformations'

import Icon from '@material-ui/icons/DateRange'
import { classType, levelList, workflowSteps, categoryList } from '../enumLists'
import rowStyle from './rowStyle'
import { intervalTimeFormatter } from '../Utilities/transformations'
import { withStyles } from '@material-ui/core'

export const trainingPlansIcon = Icon

const TimeField = ({source, record = {}}) => <span>{intervalTimeFormatter(record[source])}</span>
const FullNameField = ({record = {}}) => <span>{record.firstName} {record.lastName}</span>
FullNameField.defaultProps = {label: 'Name'}

const styles = {
  form: {width:'100%'},
}

export const trainingPlansList = (props) => (
  <List title="Training Plans" {...props} bulkActionButtons={false} perPage={50} pagination={<ListPagination/>}>
    <Datagrid rowStyle={rowStyle} rowClick="edit">
      <NumberField source="id"/>
      <TextField source="name"/>
      <NumberField source="classes.length" label="# Classes" sortable={false}/>
      <SelectField source="workflowStep" label="Workflow"
                   choices={workflowSteps}/>
    </Datagrid>
  </List>
)

export const trainingPlansEdit = withStyles(styles)(({classes,  ...props }) => (
  <Edit  {...props} undoable={false}>
    <TabbedForm>
      <FormTab label="info">
        <TextInput source="name"
                   validate={[required()]}/>
        <TextInput source="description" multiline fullWidth
                       validate={[required()]}/>
        <TextInput source="shortDescription" label="Short Description"
                   validate={[required(), maxLength(200, 'Max length is 200')]}/>
        <NumberInput source="frequency" label="# of classes per week"
                     validate={[minValue(1, 'Min is 1'), maxValue(7, 'Max is 7')]}/>
        <NumberInput source="numberOfSkipsAllowed" label="# of Skips(not supported)"
                     validate={[minValue(0, 'Min is 0'), maxValue(16, 'Max is 16')]}/>

        <FileInput source="activePlanCellPhoto" label="Active Plan Cell Photo"
                   accept="image/*"
                   maxSize={500000}>
          <FileField source="src" title="title"/>
        </FileInput>
        <ImageField source="activePlanCellPhotoUrl"/>
        <FileField source="activePlanCellPhotoUrl" title="Download Photo"/>
        <Divider style={{marginTop: '15px'}} fullWidth/>

        <FileInput source="selectPlanCellPhoto" label="Select Plan Cell Photo"
                   accept="image/*"
                   maxSize={500000}>
          <FileField source="src" title="title"/>
        </FileInput>
        <ImageField source="selectPlanCellPhotoUrl"/>
        <FileField source="selectPlanCellPhotoUrl" title="Download Photo"/>
        <Divider style={{marginTop: '15px'}} fullWidth/>

        <FileInput source="planOverviewCellPhoto" label="Plan Overview Cell Photo"
                   accept="image/*"
                   maxSize={500000}>
          <FileField source="src" title="title"/>
        </FileInput>
        <ImageField source="planOverviewCellPhotoUrl"/>
        <FileField source="planOverviewCellPhotoUrl" title="Download Photo"/>
        <Divider style={{marginTop: '15px'}} fullWidth/>

        <FileInput source="planOverviewHeaderPhoto" label="Plan Overview Header Photo"
                   accept="image/*"
                   maxSize={500000}>
          <FileField source="src" title="title"/>
        </FileInput>
        <ImageField source="planOverviewHeaderPhotoUrl"/>
        <FileField source="planOverviewHeaderPhotoUrl" title="Download Photo"/>
        <Divider style={{marginTop: '15px'}} fullWidth/>

        <FileInput source="celebrationPhoto" label="Celebration Photo"
                   accept="image/*"
                   maxSize={500000}>
          <FileField source="src" title="title"/>
        </FileInput>
        <ImageField source="celebrationPhotoUrl"/>
        <FileField source="celebrationPhotoUrl" title="Download Photo"/>
        <Divider style={{marginTop: '15px'}} fullWidth/>


        <SelectInput source="workflowStep" label="workflow"
                     choices={workflowSteps}
                     validate={[required()]}/>
        <TextField source="uuid" label="UUID(For debug)" fullWidth/>

      </FormTab>
      <FormTab label="Edit Classes" className={classes.form}>
        <ArrayInput source="classes">
          <SimpleFormIterator>

            <ReferenceInput label="Class Name" source="id"
                            reference="class"
                            allowEmpty
                            perPage={10000}
                            filter={{type: 1}}
                            sort={{field: 'name', order: 'ASC'}}>
              <SelectInput/>
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="View Classes" >
        <ReferenceArrayField label="Classes" reference="class" source="classList" className={classes.form}>
          <Datagrid rowStyle={rowStyle} rowClick="edit">
            <NumberField source="id"/>
            <TextField source="name"/>
            <ReferenceField source="instructor" label="Instructor Name"
                            reference="instructor">
              <FullNameField source="firstName"/>
            </ReferenceField>
            <SelectField source="level"
                         choices={levelList}/>
            <TimeField source="duration" label="Duration"/>
            <NumberField source="intervals.length" label="# Intervals" sortable={false}/>
            <SelectField source="category"
                         choices={categoryList}/>
            <SelectField source="type"
                         choices={classType}/>
            <SelectField source="workflowStep" label="Workflow"
                         choices={workflowSteps}/>
          </Datagrid>
        </ReferenceArrayField>
      </FormTab>
    </TabbedForm>


  </Edit>
))

export const trainingPlansCreate = withStyles(styles)(({classes,  ...props }) => (
  <Create  {...props}>
    <TabbedForm>
      <FormTab label="info">

        <TextInput source="name"
                   validate={[required()]}/>
        <TextInput source="description" multiline fullWidth
                       validate={[required()]}/>
        <TextInput source="shortDescription" label="Short Description"
                   validate={[required(), maxLength(200, 'Max length is 200')]}/>
        <NumberInput source="frequency" label="# of classes per week"
                     validate={[minValue(1, 'Min is 1'), maxValue(7, 'Max is 7')]}/>
        <NumberInput source="numberOfSkipsAllowed" label="# of Skips(Not supported)"
                     validate={[minValue(0, 'Min is 0'), maxValue(16, 'Max is 16')]}/>

        <FileField source="activePlanCellPhotoUrl" title="activePlanCellPhotoUrl"/>
        <FileInput source="activePlanCellPhoto" label="Active Plan Cell Photo"
                   accept="image/*"
                   maxSize={500000}>
          <FileField source="src" title="title"/>
        </FileInput>

        <FileField source="selectPlanCellPhotoUrl" title="activePlanCellPhotoUrl"/>
        <FileInput source="selectPlanCellPhoto" label="Select Plan Cell Photo"
                   accept="image/*"
                   maxSize={500000}>
          <FileField source="src" title="title"/>
        </FileInput>

        <FileField source="planOverviewCellPhotoUrl" title="activePlanCellPhotoUrl"/>
        <FileInput source="planOverviewCellPhoto" label="Plan Overview Cell Photo"
                   accept="image/*"
                   maxSize={500000}>
          <FileField source="src" title="title"/>
        </FileInput>

        <FileField source="planOverviewHeaderPhotoUrl" title="activePlanCellPhotoUrl"/>
        <FileInput source="planOverviewHeaderPhoto" label="Plan Overview Header Photo"
                   accept="image/*"
                   maxSize={500000}>
          <FileField source="src" title="title"/>
        </FileInput>

        <FileField source="celebrationPhotoUrl" title="celebrationPhotoUrl"/>
        <FileInput source="celebrationPhoto" label="Celebration Photo"
                   accept="image/*"
                   maxSize={500000}>
          <FileField source="src" title="title"/>
        </FileInput>

        <SelectInput source="workflowStep" label="workflow"
                     choices={workflowSteps}
                     validate={[required()]}/>

      </FormTab>
      <FormTab label="Classes" style={{width:'100%'}}>
        <ArrayInput source="classes">
          <SimpleFormIterator>
            <ReferenceInput label="Class Name" source="id"
                            reference="class"
                            allowEmpty
                            perPage={10000}
                            filter={{type: 1}}
                            sort={{field: 'name', order: 'ASC'}}>
              <SelectInput/>
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>

  </Create>
))
