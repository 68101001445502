//record -> input
import Pagination from 'ra-ui-materialui/esm/list/pagination/Pagination'
import React from 'react'
import moment from 'moment'

export const intervalTimeFormatter = time => {
    if (isNaN(time)) return;
    var minutes = Math.floor(time / 60);
    var seconds = time % 60;
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
};

//input -> record
export const intervalTimeParser = time => {
    var minutes = parseInt(time.split(":")[0], 10);
    var seconds = parseInt(time.split(":")[1], 10);
    if(seconds > 60){
        minutes=minutes*10+Math.floor(seconds/100);
        seconds=(seconds % 100);
    }
    return (minutes * 60) + seconds;
};

export const dateTimeParser = time => {
    console.log(time)
    return moment(time*1000).format('ddd, MMM Do YYYY, h:mma')
}

//input -> record
export const intervalInclineParser = incline => {
    if(incline === ""){
        return null;
    }
    return incline;
};

export const ListPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100, 500]} {...props} />
