import React from 'react';
import Icon from '@material-ui/icons/Star';

const style = { opacity: 0.87, width: 17, height: 17 };

const StarRatingField = ({ record }) => (
    <span style={{display:'block',minWidth:100}}>
        {Array(record.rating).fill(true).map((_, i) => <Icon key={i} style={style} />)}
    </span>
);

StarRatingField.defaultProps = {
    label: 'Rating',
    source: 'rating',
    addLabel: true,
};

export default StarRatingField;
