import React from 'react'

import {
  Datagrid,
  ReferenceField,
  TextField,
  List,
  Show,
  ArrayField,
  SimpleShowLayout,
} from 'react-admin'

import Icon from '@material-ui/icons/Person'

export const ClassRankingIcon = Icon

export const ClassRankingList = (props) => (
  <List title="Ranking" {...props} bulkActionButtons={false} >
    <Datagrid rowClick="show">
        <TextField source="name"/>
    </Datagrid>
  </List>
)

export const ClassRankingShow = (props) => (
  <Show title="Ranking" {...props} >
    <SimpleShowLayout>
      <TextField source="name"/>
      <ArrayField source="classes" label="">
        <Datagrid>
          <TextField source="rank"/>
          <ReferenceField label="Class" source="classId" reference="class">
            <TextField source="name"/>
          </ReferenceField>
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
)


