import { fetchUtils } from 'react-admin'
import properties from './Resources/properties'
import sailsClient from './sailsClient'


const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'})
  }
  const token = sessionStorage.getItem('token')
  options.headers.set('Authorization', `Bearer ${token}`)
  options.headers.set('app-os','cms')
  return fetchUtils.fetchJson(url, options)
}

export default sailsClient(properties.host, httpClient);