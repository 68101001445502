// in src/posts.js
import React from 'react'

import {
  SelectField,
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  TextInput,
  required,
  SimpleForm,
  SelectInput,
  BooleanInput,
  NumberInput,
  DateField,
  DateInput,
  BooleanField, minValue, maxValue, maxLength, minLength,NumberField,FormDataConsumer
} from 'react-admin'

import {
  promotionTypeList,
} from '../enumLists'


import Icon from '@material-ui/icons/AttachMoney'
import PromotionEditActions from './PromotionEditActions'

import rowStyle from './rowStyle'
import {ListPagination} from '../Utilities/transformations'

export const PromotionIcon = Icon


export const PromotionList = (props) => (

  <List title="Promotions" {...props} pagination={<ListPagination />} bulkActionButtons={false} perPage={100} sort={{field: 'id', order: 'DESC'}}>
    <Datagrid rowStyle={rowStyle} rowClick="edit">
      <TextField source="id"/>
      <TextField source="name"/>
      <SelectField source="promotionType" label="Type"
                   choices={promotionTypeList}/>
      <TextField source="vanityCode"/>
      <NumberField source="numberUsed" label="Number Used"/>
      <DateField source="expiration" />
      <BooleanField source="enabled"/>
    </Datagrid>
  </List>
)

export const PromotionEdit = (props) => (
  <Edit {...props} undoable={false} actions={<PromotionEditActions/>}>
    <SimpleForm>

      <TextInput source="name"
                 label="Promotion Name"
                 validate={[required()]}/>
      <SelectInput source="promotionType" label="Promotion Type"
                   choices={promotionTypeList}/>

      <FormDataConsumer>
        {({formData, ...rest}) =>
          (formData.promotionType === 3)  &&
          <TextInput source="vanityCode" label="Vanity Code"
                     validate={[required(), minLength(2, "Min length is 2"), maxLength(15, "Max length is 15")]}/>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData, ...rest}) =>
          (formData.promotionType === 2 || formData.promotionType === 3) &&
          <NumberInput source="numberOfFreeDays" label="Number of Free Days"
                       validate={[required()]}/>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData, ...rest}) =>
          (formData.promotionType === 3) &&
          <NumberInput source="maxUses" label="Max Uses"
                       validate={[required(),minValue(0, "Min is 0")]}/>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData, ...rest}) =>
          (formData.promotionType === 2) &&
          <NumberInput source="maxUses" label="Number to Generate"
                       validate={[required(), minValue(0, "Min is 0"), maxValue(2000000, "Max is 2,000,000")]} />
        }
      </FormDataConsumer>

      <DateInput source="expiration"
                 label="Expiration"
                 options={{ format: 'MM/DD/YYYY' }}
                 validate={[required()]}/>
      <BooleanInput source="enabled"/>

    </SimpleForm>
  </Edit>
)

export const PromotionCreate = (props) => (
  <Create  {...props}>
    <SimpleForm>

      <TextInput source="name"
                 label="Promotion Name"
                 validate={[required()]}/>
      <SelectInput source="promotionType" label="Promotion Type"
                   choices={promotionTypeList}/>

      <FormDataConsumer>
        {({formData, ...rest}) =>
          (formData.promotionType === 3)  &&
          <TextInput source="vanityCode" label="Vanity Code"
                     validate={[required(), minLength(2, "Min length is 2"), maxLength(15, "Max length is 15")]}/>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData, ...rest}) =>
          (formData.promotionType === 2 || formData.promotionType === 3) &&
          <NumberInput source="numberOfFreeDays" label="Number of Free Days"
                       validate={[required()]}/>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData, ...rest}) =>
          (formData.promotionType === 3) &&
          <NumberInput source="maxUses" label="Max Uses"
                       validate={[required(),minValue(0, "Min is 0")]}/>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData, ...rest}) =>
          (formData.promotionType === 2) &&
          <NumberInput source="maxUses" label="Number to Generate"
                       validate={[required(), minValue(0, "Min is 0"), maxValue(2000000, "Max is 2,000,000")]} />
        }
      </FormDataConsumer>

      <DateInput source="expiration"
                 label="Expiration"
                 options={{ format: 'MM/DD/YYYY' }}
                 validate={[required()]}/>
      <BooleanInput source="enabled"/>
    </SimpleForm>
  </Create>
)
