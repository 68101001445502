// in src/MyLoginPage.js
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userLogin } from 'react-admin'
import GoogleLogin from 'react-google-login'

const failureGoogle = (response) => {
  window.alert('Invalid login: ' + response.reason)

  return console.log(response)
}

class loginPage extends Component {
  onSucess = (response) => {
    const credentials = {'token': response.tokenId}

    // Dispatch the userLogin action (injected by connect)
    this.props.userLogin(credentials)
  }

  render () {
    return (
      <div style={{
        display: 'flex',
        minHeight: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#001c66',
      }}>
        <div style={{
          marginTop: '6em',
          backgroundColor:'#fff',
          boxShadow:'0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
          borderRadius:'4px'

        }}>
          <h2 style={{margin:'1em',color:'#676767'}}>ASICS Studio Admin Panel</h2>
          <div style={{margin:'1em',padding:'0 1em 1em 4em'}}>
          <GoogleLogin
            clientId="37488006683-3msoce6kf8a992e17lpk61mpfvsrv2mn.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={this.onSucess}
            onFailure={failureGoogle}
            scope="openid email"
            // hostedDomain="runkeeper.com"
          />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(undefined, {userLogin})(loginPage)
