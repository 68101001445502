import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import FileDownload from '@material-ui/icons/GetApp'
import properties from '../Resources/properties'
import classnames from 'classnames'
import blueGrey from '@material-ui/core/colors/blueGrey'
import { withStyles } from '@material-ui/core/styles'


var styles = function styles (theme) {
  return {

    desktopLink: {
      display: 'inline-flex',
      alignItems: 'center',
      color: blueGrey[500],
    },

  }
}

const ExportDataButton = ({
                            className,
                            classes = {},
                            translate,
                            label = 'ra.action.create',
                            record,
                            ...rest
                          }) => {
  if (!record) return <span/>
  return (
    <Button
      children=''
      className = {classnames (classes.desktopLink, className)}

      type="submit" onClick={function () {
      return download(record.id)
    }}
      {...rest}
    >
      <FileDownload/>
      Export User Data
    </Button>
  )
}

function download (id) {
  const token = sessionStorage.getItem('token')

  let xhttp = new XMLHttpRequest()
  xhttp.open('GET', properties.host + '/user/' + id + '/export', true)
  xhttp.setRequestHeader('Authorization', 'bearer ' + token)
  xhttp.setRequestHeader('Accept-Encoding', 'gzip')
  xhttp.responseType = 'arraybuffer'
  xhttp.send()

  xhttp.onreadystatechange = function () {
    if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
      let data = xhttp.response
      const blob = new Blob([data], {type: 'octet/stream'})
      const fileName = 'user_data_' + id + '.zip'
      const objectUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')

      a.href = objectUrl
      a.download = fileName
      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
      URL.revokeObjectURL(objectUrl)

    }
  }
}

ExportDataButton.propTypes = {
  record: PropTypes.object,
  reviewApprove: PropTypes.func,
  translate: PropTypes.func,
}

export default withStyles(styles)(ExportDataButton)
