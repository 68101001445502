import React from 'react'

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  Filter,
  SelectInput,
} from 'react-admin'

import StarRatingField from './StarRatingField'

import Icon from '@material-ui/icons/Comment'
import { sentimentList } from '../enumLists'
import {ListPagination} from '../Utilities/transformations'

export const RatingsIcon = Icon


export const RatingsList = (props) => (
  <List title="Ratings" bulkActionButtons={false} {...props} filters={<RatingFilter/>} perPage={50}  pagination={<ListPagination/>} sort={{field: 'id', order: 'DESC'}}>
    <Datagrid >
      <DateField source="createdAt" label="Date"/>
      <TextField source="userId"/>
      <ReferenceField label="class" source="classId" reference="class">
        <TextField source="name"/>
      </ReferenceField>
      <StarRatingField />
      <TextField source="comment" style={{maxWidth: '30em', overflow: 'hidden', textOverflow: 'ellipsis'}}/>
      <TextField source="sentiment"/>
    </Datagrid>
  </List>
)

const RatingFilter = (props) => (
  <Filter {...props}>
    <SelectInput label="Sentiment" source="sentiment"
                 choices={sentimentList}/>
  </Filter>
)
