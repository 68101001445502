// in src/posts.js
import React from 'react'

import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  SelectField,
  NumberField,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin'


import Icon from '@material-ui/icons/ThumbUp'
import { fitnessGoalList } from '../enumLists'

export const RecommendationIcon = Icon

export const RecommendationList = (props) => (
  <List title="Recommendations" {...props} bulkActionButtons={false} perPage={50}>
    <Datagrid rowClick="edit" >
      <SelectField source="fitnessGoal"
                   label="Fitness Goal"
                   choices={fitnessGoalList}/>
      <NumberField source="classes.length" label="Number of Classes"/>
    </Datagrid>
  </List>
)

export const RecommendationEdit = (props) => (
  <Edit  {...props}>
    <SimpleForm style={{width: '100%'}}>
      <SelectInput source="fitnessGoal"
                   choices={fitnessGoalList}/>
      <ArrayInput source="classes" style={{width: '100%'}}>
        <SimpleFormIterator>
          <ReferenceInput label="Class Name" source="id"
                        reference="class"
                        allowEmpty
                        perPage={10000}
                        filter={{workflowStep: 2}}
                        sort={{field: 'name', order: 'ASC'}}>
          <SelectInput/>
        </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>


  </Edit>
)

export const RecommendationCreate = (props) => (
  <Create  {...props}>
    <SimpleForm style={{width: '100%'}}>
      <SelectInput source="fitnessGoal"
                   choices={fitnessGoalList}/>
      <ArrayInput source="classes" style={{width: '100%'}}>
        <SimpleFormIterator>
          <ReferenceInput label="Class Name" source="id"
                        reference="class"
                        allowEmpty
                        perPage={10000}
                        filter={{workflowStep: 2}}
                        sort={{field: 'name', order: 'ASC'}}>
          <SelectInput/>
        </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>


  </Create>
)
