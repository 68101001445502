import React from 'react'
import {TopToolbar} from 'react-admin'
import ExportDataButton from './ExportDataButton'
import LogoutUserButton from './LogoutUserButton'


const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
}

const UserEditActions = ({basePath, data, resource}) => (
  <TopToolbar style={cardActionStyle}>
    <LogoutUserButton  record={data}/>
    <ExportDataButton record={data}/>
  </TopToolbar>
)

export default UserEditActions
