// in src/posts.js
import React from 'react';

import {
    SelectField,
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    TextInput,
    required,
    SimpleForm,
    SelectInput,
    BooleanInput,
    BooleanField
} from 'react-admin';

import {
    accessList
} from '../enumLists';

import Icon from '@material-ui/icons/Lock';
import {ListPagination} from '../Utilities/transformations'

export const AccessIcon = Icon;

const Aside = () => (
  <div style={{ width: 350, margin: '2em' }}>
    <h1 variant="title">Access Help</h1>
    <p>This is where access to this admin panel is controlled.</p>
    <p><strong>Role:&nbsp;</strong></p>
    <p>Editor: access to only the info needed to create and edit classes</p>
    <p>Admin: access to everything including user data. This should&nbsp;<strong>not&nbsp;</strong>be granted to people outside the ASICS org at any time.</p>
    <p><strong>Debug Mode:</strong></p>
    <p>Enabled: User will see classes that are in review as well as published.</p>
    <p>Disabled: User will see only published classes.</p>
  </div>
);

export const AccessList = (props) => (
    <List title="Access Control" {...props} perPage={100} pagination={<ListPagination/>} bulkActionButtons={false} sort={{ field: 'id', order: 'ASC' }}>
        <Datagrid rowClick="edit">
            <TextField source="email"/>
            <SelectField source="role" label="Role"
                         choices={accessList}/>
            <BooleanField source="debugMode" label="Debug"/>
        </Datagrid>
    </List>
);

export const AccessEdit = (props) => (
    <Edit title="Edit Access" {...props} aside={<Aside />}>
        <SimpleForm>
                <TextInput source="email"
                           validate={[required()]}/>
            <SelectInput source="role" label="Role"
                         choices={accessList}
                         validate={[required()]}/>
            <BooleanInput source="debugMode" label="Debug Mode"/>
        </SimpleForm>
    </Edit>
);

export const AccessCreate = (props) => (
    <Create  {...props} aside={<Aside />}>
        <SimpleForm>
            <TextInput source="email"
                       validate={[required()]}/>
            <SelectInput source="role" label="Role"
                         choices={accessList}
                         validate={[required()]}/>
            <BooleanInput source="debugMode" label="Debug Mode"/>

        </SimpleForm>
    </Create>
);
