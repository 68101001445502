// in src/posts.js
import React from 'react'

import {
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  DateField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
} from 'react-admin'

import Icon from '@material-ui/icons/AccessTime'
import { ListPagination } from '../Utilities/transformations'

export const WorkoutOfTheDayIcon = Icon

export const WorkoutOfTheDayList = (props) => (
  <List title="Workout Of The Day" bulkActionButtons={false} {...props} pagination={<ListPagination/>} perPage={50} sort={{field: 'date', order: 'DESC'}}>
    <Datagrid rowClick="edit">
      <DateField source="date"/>
      <ReferenceField label="class" source="classId" reference="class">
        <TextField source="name"/>
      </ReferenceField>
    </Datagrid>
  </List>
)

export const WorkoutOfTheDayEdit = (props) => (
  <Edit  {...props}>
    <SimpleForm>

      <DateField source="date"/>
      <ReferenceInput label="class" source="classId" reference="class">
        <SelectInput/>
      </ReferenceInput>
    </SimpleForm>


  </Edit>
)
