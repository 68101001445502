// in src/posts.js
import React from 'react';

import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  required,
  SimpleForm,
  FileInput,
  FileField,
  SelectInput,
  SelectField,
  DateField,
  BooleanField,
  BooleanInput,
  TopToolbar,
  ListButton,
  RefreshButton
} from 'react-admin';

import Icon from '@material-ui/icons/FitnessCenter';
import { exerciseListSorted } from '../enumLists'
import {ListPagination} from '../Utilities/transformations'

export const ExerciseIcon = Icon;

const ExerciseEditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

export const ExerciseList = (props) => (
  <List title="Exercises" {...props} bulkActionButtons={false} filters={<ExerciseFilter/>} perPage={100} pagination={<ListPagination/>} sort={{field: 'id', order: 'ASC'}} >
    <Datagrid rowClick="edit" >
      <SelectField source="exercise"
                   choices={exerciseListSorted}/>
      <BooleanField source="published"/>
    </Datagrid>
  </List>
);

export const ExerciseEdit = (props) => (
  <Edit actions={<ExerciseEditActions/>} {...props}>
    <SimpleForm>
      <SelectInput source="exercise" label="Exercise"
                   choices={exerciseListSorted}
                   validate={[required()]}/>
      <FileField source="videoUrl" title="videoUrl" fullWidth/>
      <FileInput source="exerciseVideo" label="Replace Exercise Video(Max size 5MB)"
                 accept="video/*"
                 maxSize={5000000}>
        <FileField source="src" title="title" />
      </FileInput>
      <BooleanInput source="published"/>
      <DateField source="createdAt" label="Created"
                 style={{display:'inline-block', 'minWidth':'15%'}}/>
      <DateField source="updatedAt" label="Last updated"
                 style={{display:'inline-block', 'minWidth':'15%'}}/>
    </SimpleForm>


  </Edit>
);

export const ExerciseCreate = (props) => (
  <Create  {...props}>
    <SimpleForm>
      <SelectInput source="exercise" label="Exercise"
                   choices={exerciseListSorted}
                   validate={[required()]}/>
      <FileInput source="exerciseVideo" label="Exercise Video(max size 5MB)"
                 accept="video/*"
                 validate={[required()]}
                 maxSize={5000000}>

        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

const ExerciseFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="exercise" choices={exerciseListSorted} style={{ 'width':'250px'}}/>
  </Filter>
);


