// in src/posts.js
import React from 'react'

import {
  SelectField,
  NumberField,
  NumberInput,
  Filter,
  List,
  Edit,
  Create,
  Datagrid,
  ReferenceField,
  TextField,
  ReferenceInput,
  SelectInput,
  TextInput,
  TabbedForm,
  FormTab,
  minValue,
  maxValue,
  required,
  maxLength,
  DateField,
  ReferenceManyField,
  CheckboxGroupInput,
  FileInput,
  FileField,
  BooleanInput,
  ArrayInput,
  FormDataConsumer,
  ImageField
} from 'react-admin'
import { SimpleFormIteratorCustom } from './SimpleFormIteratorCustom'
import ClassDownloadButton from './ClassDownloadButton'
import { DateTimeInput } from 'react-admin-date-inputs2'
import { TopToolbar, CreateButton } from 'react-admin'
import { ListPagination } from '../Utilities/transformations'

import StarRatingField from '../ratings/StarRatingField'

import Icon from '@material-ui/icons/DirectionsRun'

import {
  equipmentList,
  categoryList,
  levelList,
  exerciseListSorted,
  workflowSteps,
  durationCategoryList,
  classType
} from '../enumLists'

import {
  intervalTimeFormatter,
  intervalInclineParser,
} from '../Utilities/transformations'

import rowStyle from './rowStyle'
import AllClassesDownloadButton from './AllClassesDownloadButton'
import { withStyles } from '@material-ui/core/styles/index'
import SimpleForm from 'ra-ui-materialui/esm/form/SimpleForm'

export const ClassIcon = Icon

const styles = {
  created: {display: 'inline-block'},
  last_updated: {display: 'inline-block', marginLeft: 32},
  form: {width: '100%'},
}

const ClassListActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (

  <TopToolbar>
    {filters && React.cloneElement(filters, {resource, showFilter, displayedFilters, filterValues, context: 'button'})}
    <CreateButton basePath={basePath}/>
    <AllClassesDownloadButton/>
  </TopToolbar>
)

const ClassEditActions = ({basePath, data, hasDelete, hasShow}) => (

  <TopToolbar>
    <ClassDownloadButton record={data}/>
  </TopToolbar>
)

const TimeField = ({source, record = {}}) => <span>{intervalTimeFormatter(record[source])}</span>
const FullNameField = ({record = {}}) => <span>{record.firstName} {record.lastName}</span>
FullNameField.defaultProps = {label: 'Name'}
const FullNameRenderer = choice => `${choice.firstName} ${choice.lastName}`

export const ClassList = (props) => (
  <List title="Classes" {...props} pagination={<ListPagination/>} bulkActionButtons={false} filters={<ClassFilter/>} perPage={50} sort={{field: 'id', order: 'DESC'}}
        actions={<ClassListActions/>}>
    <Datagrid rowStyle={rowStyle} rowClick="edit">
      <NumberField source="id"/>
      <TextField source="name"/>
      <ReferenceField source="instructor" label="Instructor Name"
                      reference="instructor">
        <FullNameField source="firstName"/>
      </ReferenceField>
      <SelectField source="level"
                   choices={levelList}/>
      <TimeField source="duration" label="Duration"/>
      <NumberField source="intervals.length" label="# Intervals" sortable={false}/>
      <SelectField source="category"
                   choices={categoryList}/>
      <SelectField source="type"
                   choices={classType}/>
      <SelectField source="workflowStep" label="Workflow"
                   choices={workflowSteps}/>
    </Datagrid>
  </List>
)

export const ClassCreate = withStyles(styles)(({classes, ...props}) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="info">
        <TextInput source="name"
                   validate={[required(), maxLength(23, 'Max length is 23')]}/>
        <TextInput source="description" multiline
                       validate={[required(), maxLength(250, 'Max length is 250')]}/>
        <SelectInput source="category" choices={categoryList}
                     validate={[required()]}/>
        <FormDataConsumer>
          {({formData, ...rest}) =>
            ([7,8,9].indexOf(formData.category) === -1) &&
            <CheckboxGroupInput source="equipment" label="Equipment"
                                choices={equipmentList}/>
          }
        </FormDataConsumer>

        <SelectInput source="level" choices={levelList}
                     validate={[required()]}/>
        <ReferenceInput label="Instructor" source="instructor" reference="instructor"
                        sort={{field: 'firstName', order: 'ASC'}}
                        validate={[required()]}
                        allowEmpty>
          <SelectInput optionText={FullNameRenderer}/>
        </ReferenceInput>

        <FormDataConsumer>
          {({formData, ...rest}) =>
            ([7,8,9].indexOf(formData.category) > -1) &&
            <FileInput source="workoutVideo" label="Video file">
              <FileField source="src" title="title"/>
            </FileInput>
          }
        </FormDataConsumer>


        <FormDataConsumer>
          {({formData, ...rest}) =>
            ([7,8,9].indexOf(formData.category) > -1) &&
            <FileInput source="previewImage" label="Preview Image"
                       accept="image/*"
                       maxSize={600000}>
              <FileField source="src" title="title"/>
            </FileInput>

          }
        </FormDataConsumer>


        <FormDataConsumer>
          {({formData, ...rest}) =>
            ([7,8,9].indexOf(formData.category) === -1) &&
            <FileInput source="instruction" label="Audio file">
              <FileField source="src" title="title"/>
            </FileInput>
          }
        </FormDataConsumer>

        <ReferenceInput label="Playlist" source="playlist" reference="playlist"
                        allowEmpty
                        validate={[required()]}>
          <SelectInput optionText="name"
                       optionValue="name"/>
        </ReferenceInput>
        <SelectInput source="type"
                     choices={classType}
                     validate={[required()]}/>
      </FormTab>
      <FormTab label="Intervals" style={{width: '100%'}}>
        <ArrayInput source="intervals">
          <SimpleFormIteratorCustom>

            <SelectInput source="exercise" label="Exercise"
                            choices={exerciseListSorted}
                            validate={[required()]}
                            style={{width: '300px'}}
                            allowEmpty/>

            <NumberInput source="duration" label="Duration(sec)"
                         style={{width: '120px'}}/>


            <NumberInput source="endTimeMin" label="min"
                         validate={[minValue(0, 'greater than 0')]}
                         style={{width: '80px'}}/>

            <NumberInput source="endTimeSec" label="sec"
                         validate={[minValue(0, 'greater than 0'), maxValue(59, 'less than 59')]}
                         style={{width: '80px'}}/>

            <NumberInput source="incline" label="Incline"
                         validate={[minValue(0, 'Min incline is 0'), maxValue(15, 'Max incline is 15')]}
                         parse={intervalInclineParser}
                         style={{width: '80px'}}
            />
          </SimpleFormIteratorCustom>
        </ArrayInput>
      </FormTab>
      <FormTab label="Notes">
        <TextInput multiline rows={'10'} fullWidth source="notes"/>
      </FormTab>
    </TabbedForm>
  </Create>
))

export const ClassEdit = withStyles(styles)(({classes, ...props}) => (

  <Edit title={<ClassTitle/>} undoable={false} {...props} actions={<ClassEditActions/>}>
    <TabbedForm redirect="edit">
      <FormTab label="info">
        <TextInput source="id" disabled/>
        <TextInput source="duration" disabled label="Duration(min:sec)" format={intervalTimeFormatter}/>
        <TextInput source="name"
                   validate={[required(), maxLength(23, 'Max length is 23')]}/>
        <TextInput source="description" multiline fullWidth
                       validate={[required(), maxLength(250, 'Max length is 250')]}/>
        <SelectField source="category"
                     choices={categoryList}/>
        <FormDataConsumer>
          {({formData, ...rest}) =>
            ([7,8,9].indexOf(formData.category) === -1) &&
            <CheckboxGroupInput source="equipment" label="Equipment"
                                choices={equipmentList}/>
          }
        </FormDataConsumer>

        <SelectInput source="level"
                     choices={levelList}
                     validate={[required()]}
        />
        <ReferenceInput label="Instructor" source="instructor"
                        sort={{field: 'firstName', order: 'ASC'}}
                        reference="instructor"
                        validate={[required()]}
                        allowEmpty>
          <SelectInput optionText={FullNameRenderer}/>
        </ReferenceInput>

        <FormDataConsumer>
          {({formData, ...rest}) =>
            ([7,8,9].indexOf(formData.category) > -1) &&
            <FileInput source="workoutVideo" label="Video file">
              <FileField source="src" title="title"/>
            </FileInput>
          }
        </FormDataConsumer>
        <FileField source="workoutVideoUrl" title="workoutVideoUrl" fullWidth/>

        <FormDataConsumer>
          {({formData, ...rest}) =>
            ([7,8,9].indexOf(formData.category) > -1) &&
            <FileInput source="previewImage" label="Preview Image"
                       accept="image/*"
                       maxSize={600000}>
              <FileField source="src" title="title"/>
            </FileInput>
          }
        </FormDataConsumer>
        <ImageField source="previewImageUrl" title="previewImageUrl" fullWidth/>


        <FormDataConsumer>
          {({formData, ...rest}) =>
            ([7,8,9].indexOf(formData.category) === -1) &&
            <FileInput source="instruction" label="Audio file">
              <FileField source="src" title="title"/>
            </FileInput>
          }
        </FormDataConsumer>
        <FileField source="instructionUrl" title="instructionUrl" fullWidth/>


        <ReferenceInput label="Playlist" source="playlist" reference="playlist"
                        allowEmpty
                        validate={[required()]}>
          <SelectInput optionValue="name"/>
        </ReferenceInput>

        <SelectInput source="type"
                     choices={classType}
                     validate={[required()]}/>
        <SelectInput source="workflowStep" label="workflow"
                     choices={workflowSteps}
                     validate={[required()]}/>
        <FormDataConsumer>
          {({formData, ...rest}) =>
            formData.workflowStep === 1 &&
            <BooleanInput source="releaseScheduled" label="Schedule class for automatic release?"/>
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({formData, ...rest}) =>
            formData.releaseScheduled &&
            <DateTimeInput source="releaseDate" label="Scheduled Date"/>
          }
        </FormDataConsumer>
        <DateField source="createdAt" label="Created"
                   formClassName={classes.created}
        />
        <DateField source="updatedAt" label="Last updated"
                   formClassName={classes.last_updated}
        />

      </FormTab>
      <FormTab label="Intervals" >
        <ArrayInput source="intervals" style={{width: '100%'}}>
          <SimpleFormIteratorCustom>

            <SelectInput source="exercise" label="Exercise"
                            choices={exerciseListSorted}
                            validate={[required()]}
                            style={{width: '300px'}}
                            allowEmpty/>

            <NumberInput source="duration" label="Duration(sec)"
                         style={{width: '120px'}}/>


            <NumberInput source="endTimeMin" label="min"
                         validate={[minValue(0, 'greater than 0')]}
                         style={{width: '80px'}}/>

            <NumberInput source="endTimeSec" label="sec"
                         validate={[minValue(0, 'greater than 0'), maxValue(59, 'less than 59')]}
                         style={{width: '80px'}}/>

            <NumberInput source="incline" label="Incline"
                         validate={[minValue(0, 'Min incline is 0'), maxValue(15, 'Max incline is 15')]}
                         parse={intervalInclineParser}
                         style={{width: '80px'}}
            />
          </SimpleFormIteratorCustom>
        </ArrayInput>
      </FormTab>
      <FormTab label="Notes">
        <TextInput multiline rows={'10'} fullWidth source="notes"/>
      </FormTab>
      <FormTab label="Ratings">
        <SimpleForm toolbar={<div/>} className={classes.form}>

          <ReferenceManyField reference="ratings" target="classId" addLabel={false}>
            <Datagrid classes={{table:classes.form}}>
              <NumberField source="id"/>
              <TextField source="userId"/>
              <ReferenceField label="class" source="classId" reference="class">
                <TextField source="name"/>
              </ReferenceField>
              <StarRatingField style={{whiteSpace: 'nowrap'}}/>
              <TextField source="comment" style={{maxWidth: '30em', overflow: 'hidden', textOverflow: 'ellipsis'}}/>
              <DateField source="createdAt"/>
            </Datagrid>
          </ReferenceManyField>
        </SimpleForm>
      </FormTab>
      <FormTab label="Transcription">

        <ReferenceField reference="transcription" source="transcriptionId" label="Machine Transcription - Not 100% accurate" link={false}>
          <TextField source="transcription"/>
        </ReferenceField>
      </FormTab>
    </TabbedForm>
  </Edit>
))

const ClassFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Instructor" source="instructor"
                    reference="instructor"
                    allowEmpty>
      <SelectInput optionText={FullNameRenderer}/>
    </ReferenceInput>
    <SelectInput source="level" choices={levelList}/>
    <SelectInput label="Duration" source="durationCategory" choices={durationCategoryList}/>
    <SelectInput label="Category" source="category"
                 choices={categoryList}/>
    <SelectInput label="Workflow" source="workflowStep"
                 choices={workflowSteps}/>
    <ReferenceInput label="Class Name" source="id"
                    reference="class"
                    allowEmpty
                    perPage={10000}
                    alwaysOn
                    sort={{field: 'name', order: 'ASC'}}>
      <SelectInput/>
    </ReferenceInput>
    <SelectInput source="type"
                 choices={classType}/>
  </Filter>
)

const ClassTitle = ({record}) => {
  return <span>Edit {record ? `"${record.name}"` : ''}</span>
}
