import { createMuiTheme } from '@material-ui/core/styles';

// Local
//  const properties ={host: 'http://localhost:1337', title:'ASICS Studio Local',
//    theme: createMuiTheme({
//      palette: {
//        secondary:{main:'#43A047'}
//      },
//      typography: {
//        useNextVariants: true,
//      }
//    })};

// Development
const properties ={host: 'https://as-api.dev.asics.digital', title:'ASICS Studio Dev',
  theme: createMuiTheme({
    palette: {
      secondary:{main:'#43A047'}
    }})};

//Production
// const properties = { host:'https://as-api.prod.asics.digital', title:'ASICS Studio Production',
//   theme: createMuiTheme({
//     palette: {
//       secondary: {main: '#607D8B'}
//     }})};

export default properties;
