import React from 'react';
import Icon from '@material-ui/icons/Done';

const style = { opacity: 0.87, width: 17, height: 17 };

const DeletedWorkoutField = ({ record }) => (
    <span style={{display:'block',minWidth:100}}>
        {record.deleted && <Icon  style={style}/>}
    </span>
);

DeletedWorkoutField.defaultProps = {
    source: 'deleted',
    addLabel: true,
};

export default DeletedWorkoutField;
