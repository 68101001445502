// in src/posts.js
import React from 'react'

import {
  Create,
  Datagrid,
  Edit,
  ImageField,
  List,
  maxLength,
  number,
  required,
  TextField,
  BooleanField,
  BooleanInput,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput, minValue, maxValue,
  SelectField,
  NumberField,
  TabbedForm,
  FormTab,
  NumberInput,
  FileField,
  FileInput,
  ReferenceArrayField,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
import Divider from '@material-ui/core/Divider'
import { ListPagination } from '../Utilities/transformations'

import Icon from '@material-ui/icons/Collections'
import { classType, levelList, workflowSteps, categoryList } from '../enumLists'
import rowStyle from './rowStyle'
import classRowStyle from './classRowStyle'

import { intervalTimeFormatter } from '../Utilities/transformations'
import { withStyles } from '@material-ui/core'

export const collectionsIcon = Icon

const TimeField = ({source, record = {}}) => <span>{intervalTimeFormatter(record[source])}</span>
const FullNameField = ({record = {}}) => <span>{record.firstName} {record.lastName}</span>
FullNameField.defaultProps = {label: 'Name'}

const styles = {
  form: {width:'100%'},
}

export const collectionsList = (props) => (
  <List title="Collections"  bulkActionButtons={false} {...props} perPage={50} pagination={<ListPagination/>}>
    <Datagrid rowStyle={rowStyle} rowClick="edit">
      <NumberField source="id"/>
      <TextField source="name"/>
      <NumberField source="index" label="Index"/>
      <NumberField source="classes.length" label="# Classes" sortable={false}/>
      <BooleanField source="enabled" label="enabled"/>
    </Datagrid>
  </List>
)

export const collectionsEdit = withStyles(styles)(({classes,  ...props }) => (
  <Edit  {...props} undoable={false}>
    <TabbedForm>
      <FormTab label="info">
        <TextInput source="name" label="Title"
                   validate={[required(),maxLength(25, 'Max length is 25')]}/>

        <TextInput source="description" label="Description" multiline fullWidth
                   validate={[required(), maxLength(250, 'Max length is 250')]}/>

        <NumberInput source="index" label="Position of Collection(lowest is first)"
                     validate={[minValue(0, 'Min is 0'), maxValue(1000, 'Max is 1000'), number('Index must be a number')]}/>

        <FileInput source="cellImage" label="Cell Image (870x570 pixels)"
                   accept="image/*"
                   maxSize={600000}>
          <FileField source="src" title="title"/>
        </FileInput>
        <ImageField source="cellImageUrl"/>
        <FileField source="cellImageUrl" title="Download Image"/>

        <Divider style={{marginTop: '15px'}} fullWidth/>

        <FileInput source="headerImage" label="Header Image (750x344 pixels)"
                   accept="image/*"
                   maxSize={600000}>
          <FileField source="src" title="title"/>
        </FileInput>
        <ImageField source="headerImageUrl"/>
        <FileField source="headerImageUrl" title="Download Image"/>

        <BooleanInput source="enabled" label="Enabled"/>
        <TextField source="uuid" label="UUID" fullWidth/>

      </FormTab>
      <FormTab label="Edit Classes" className={classes.form}>
        <ArrayInput source="classes" >
          <SimpleFormIterator>

            <ReferenceInput label="Class ID" source="id"
                            reference="class"
                            allowEmpty
                            perPage={10000}
                            filter={{workflowStep: 2}}
                            sort={{field: 'id', order: 'DESC'}}>
              <SelectInput optionText="id"/>
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="View Classes" >
        <ReferenceArrayField label="Classes" reference="class" source="classIds" className={classes.form}>
          <Datagrid rowStyle={classRowStyle} rowClick="edit">
            <NumberField source="id"/>
            <TextField source="name"/>
            <ReferenceField source="instructor" label="Instructor Name"
                            reference="instructor">
              <FullNameField source="firstName"/>
            </ReferenceField>
            <SelectField source="level"
                         choices={levelList}/>
            <TimeField source="duration" label="Duration"/>
            <NumberField source="intervals.length" label="# Intervals" sortable={false}/>
            <SelectField source="category"
                         choices={categoryList}/>
            <SelectField source="type"
                         choices={classType}/>
            <SelectField source="workflowStep" label="Workflow"
                         choices={workflowSteps}/>
          </Datagrid>
        </ReferenceArrayField>
      </FormTab>
    </TabbedForm>


  </Edit>
))

export const collectionsCreate = withStyles(styles)(({classes,  ...props }) => (
  <Create  {...props}>
    <TabbedForm>
      <FormTab label="info">

        <TextInput source="name" label="Title"
                   validate={[required(),maxLength(25, 'Max length is 25')]}/>

        <TextInput source="description" label="Description" multiline fullWidth
                   validate={[required(), maxLength(250, 'Max length is 250')]}/>

        <NumberInput source="index" label="Position of Collection(lowest is first)"
                     validate={[minValue(0, 'Min is 0'), maxValue(1000, 'Max is 1000'), number('Index must be a number')]}/>

        <FileField source="cellImageUrl" title="cellImageUrl"/>
        <FileInput source="cellImage" label="Cell Image (870x570 pixels)"
                   accept="image/*"
                   maxSize={600000}>
          <FileField source="src" title="title"/>
        </FileInput>

        <FileField source="headerImageUrl" title="headerImageUrl"/>
        <FileInput source="headerImage" label="Header Image (750x344 pixels)"
                   accept="image/*"
                   maxSize={600000}>
          <FileField source="src" title="title"/>
        </FileInput>

      </FormTab>
      <FormTab label="Classes" style={{width:'100%'}}>
        <ArrayInput source="classes">
          <SimpleFormIterator>
            <ReferenceInput label="Class ID" source="id"
                            reference="class"
                            allowEmpty
                            perPage={10000}
                            filter={{workflowStep: 2}}
                            sort={{field: 'id', order: 'DESC'}}>
              <SelectInput optionText="id"/>
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>

  </Create>
))
