// in src/App.js
import React from 'react'
import { Admin, Resource } from 'react-admin'
import loginPage from './loginPage'
import authProvider from './authProvider'
import { ClassList, ClassEdit, ClassCreate, ClassIcon } from './classes'
import { InstructorList, InstructorEdit, InstructorCreate, InstructorIcon } from './instructors'
import { ExerciseList, ExerciseEdit, ExerciseCreate, ExerciseIcon } from './exercises'
import { UserList, UserCreate,UserEdit, UserIcon } from './users'
import { WorkoutOfTheDayList,WorkoutOfTheDayEdit, WorkoutOfTheDayIcon} from './workoutOfTheDay';
import { RecommendationList,RecommendationEdit,RecommendationCreate, RecommendationIcon} from './recommendation';
import { trainingPlansList,trainingPlansEdit,trainingPlansCreate, trainingPlansIcon} from './trainingPlans';
import { collectionsList,collectionsEdit,collectionsCreate, collectionsIcon} from './collections';

import { scheduledReleaseList,scheduledReleaseIcon} from './scheduledReleases';
import { ClassRankingList, ClassRankingIcon, ClassRankingShow} from './classRanking';

import properties from './Resources/properties'

import restClient from './restClient';


import { RatingsList, RatingsIcon } from './ratings'
import { AccessList, AccessEdit, AccessCreate, AccessIcon } from './access'
import { PromotionList, PromotionCreate, PromotionEdit, PromotionIcon } from './promotion'

const App = () => (

  <Admin
    theme={properties.theme}
    title={properties.title}
    dataProvider={restClient}
    loginPage={loginPage}
    authProvider={authProvider}>
    {
      permissions => [
        <Resource name="class" list={ClassList} edit={ClassEdit} create={ClassCreate} icon={ClassIcon}/>,
        <Resource name="instructor" list={InstructorList} edit={InstructorEdit} create={InstructorCreate}  icon={InstructorIcon}/>,
        <Resource name="exercise" list={ExerciseList} edit={ExerciseEdit} create={ExerciseCreate} icon={ExerciseIcon}/>,


        <Resource name="ratings" list={RatingsList}  icon={RatingsIcon}/>,
        <Resource name="playlist"/>,
        <Resource name="workout"/>,
        <Resource name="transcription"/>,
        <Resource name="payment/transaction"/>,
        <Resource name="scheduledClassRelease" list={scheduledReleaseList} icon={scheduledReleaseIcon} options={{ label: 'Scheduled Releases' }}/>,

        parseInt(permissions, 10) === 2
          ? <Resource name="WOD"  options={{ label: 'Workout of the day' }} list={WorkoutOfTheDayList} edit={WorkoutOfTheDayEdit} icon={WorkoutOfTheDayIcon}/>
          : null,
        parseInt(permissions, 10) === 2
          ? <Resource name="Recommendation"  list={RecommendationList} edit={RecommendationEdit} create={RecommendationCreate} icon={RecommendationIcon}/>
          : null,
        parseInt(permissions, 10) === 2
          ? <Resource name="user" list={UserList} create={UserCreate} edit={UserEdit}  icon={UserIcon} />
          : null,
        parseInt(permissions, 10) === 2
          ? <Resource name="access" list={AccessList} create={AccessCreate} edit={AccessEdit} icon={AccessIcon}/>
          : null,
        parseInt(permissions, 10) === 2
          ? <Resource name="promotion" list={PromotionList} create={PromotionCreate} edit={PromotionEdit} icon={PromotionIcon}/>
          : null,
        parseInt(permissions, 10) === 2
          ? <Resource name="trainingPlans" options={{ label: 'Training Plans' }} list={trainingPlansList} create={trainingPlansCreate} edit={trainingPlansEdit} icon={trainingPlansIcon}/>
          : null,
        parseInt(permissions, 10) === 2
          ? <Resource name="collections" options={{ label: 'Collections' }} list={collectionsList} create={collectionsCreate} edit={collectionsEdit} icon={collectionsIcon}/>
          : null,
        parseInt(permissions, 10) === 2
          ? <Resource name="classRanking" options={{ label: 'Class Ranking' }} show={ClassRankingShow} list={ClassRankingList} icon={ClassRankingIcon}/>
          : null,

      ]}
  </Admin>
)

export default App

