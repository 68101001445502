import React  from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FileDownload from '@material-ui/icons/GetApp';
import properties from '../Resources/properties';
import blueGrey from '@material-ui/core/colors/blueGrey'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'


var styles = function styles(theme) {
  return {

    desktopLink: {
      display: 'inline-flex',
      alignItems: 'center',
      color: blueGrey[500]
    },

  };
};
const ClassDownloadButton = ({
                                    basePath = '',
                                    className,
                                    classes = {},
                                    translate,
                                    label = 'ra.action.create',
  record,
                                    ...rest
                                  }) => {
  if(!record) return <span/>
  return (
<Button
  children=''
  type="submit"
  className={classnames(classes.desktopLink, className)}
  onClick={function () {
    return download(record.id)
  }}
  {...rest}
>
  <FileDownload/>
  Download
</Button>
)};

function download(id) {
    const token = sessionStorage.getItem('token');

    let xhttp = new XMLHttpRequest();
    xhttp.open("GET", properties.host + '/class/print/' + id, true);
    xhttp.setRequestHeader("Authorization", 'bearer ' + token);
    xhttp.send();

  xhttp.onreadystatechange = function () {
    if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
      let data = xhttp.response
      const blob = new Blob([data], {type: 'text/csv'})
      const fileName = 'class_' + id + '.csv';
      const objectUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')

      a.href = objectUrl
      a.download = fileName
      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
      URL.revokeObjectURL(objectUrl)

    }
  }
}



ClassDownloadButton.propTypes = {
    record: PropTypes.object,
    reviewApprove: PropTypes.func,
    translate: PropTypes.func,
};

export default withStyles(styles)(ClassDownloadButton);
