// in src/authProvider.js
import { AUTH_LOGIN, AUTH_ERROR, AUTH_LOGOUT, AUTH_GET_PERMISSIONS, AUTH_CHECK } from 'react-admin'
import properties from './Resources/properties'
import decodeJwt from 'jwt-decode'

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const {token} = params
    const authHost = properties.host + '/auth'
    const request = new Request(authHost, {
      method: 'POST',
      body: JSON.stringify({token: token}),
      headers: new Headers({'Content-Type': 'application/json'}),
    })
    return fetch(request).then(response => {
      if (response.status < 200 || response.status >= 300) {
        window.alert('This user is not authorized.')
        throw new Error(response.statusText);
      }
      return response.json();
    }).then(({token}) => {
      const decodedToken = decodeJwt(token)
      sessionStorage.setItem('token', token)
      sessionStorage.setItem('role', decodedToken.role)
    })
  }

  if (type === AUTH_LOGOUT) {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('role')
    return Promise.resolve()
  }

  if (type === AUTH_ERROR) {
    const {status} = params
    if (status === 401 || status === 403) {
      sessionStorage.removeItem('token')
      return Promise.reject()
    }
    return Promise.resolve()
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const role = sessionStorage.getItem('role')
    return role ? Promise.resolve(role) : Promise.reject()
  }

  if (type === AUTH_CHECK) {
    return sessionStorage.getItem('token') ? Promise.resolve() : Promise.reject()
  }

  return Promise.reject('Unknown method')
}

