export const workflowSteps = [
  {id: 0, name: 'Edit'},
  {id: 1, name: 'Review'},
  {id: 2, name: 'Published'},
  {id: 3, name: 'Retired'},
]

export const classType = [
  {id: 0, name: 'Regular'},
  {id: 1, name: 'Training Plan'}
]

export const trainerLocationList = [
  {id: '', name: ''},
  {id: 'Boston', name: 'Boston'},
  {id: 'Los Angeles', name: 'Los Angeles'},
  {id: 'New York', name: 'New York'},
  {id: 'Anytime, Anywhere', name: 'Anytime, Anywhere'},
]

export const promotionTypeList = [
  {id: 2, name: 'One-Time use code '},
  {id: 3, name: 'Multi-use (Vanity)'},
]

export const equipmentList = [
  {id: 0, name: 'Kettlebell'},
  {id: 1, name: 'Dumbbells'},
  {id: 2, name: 'Treadmill'},
  {id: 3, name: 'Stationary Bike'},
  {id: 4, name: 'Yoga Mat'},
  {id: 5, name: 'Resistance Band'},
  {id: 6, name: 'Bench'},
  {id: 7, name: 'Jump Rope'},
  {id: 8, name: 'Elliptical'},
  {id: 9, name: 'Yoga Block'},
  {id: 10, name: 'Balance Ball'},
  {id: 11, name: 'Medicine Ball'},
  {id: 12, name: 'Foam Roller'},
]

export const categoryList = [
  {id: 0, name: 'Strength Training'},
  {id: 1, name: 'Treadmill'},
  {id: 2, name: 'Fusion'},
  {id: 3, name: 'Yoga & Meditation'},
  {id: 4, name: 'Outdoor Running'},
  {id: 5, name: 'Indoor Cycling'},
  {id: 6, name: 'Elliptical'},
  {id: 7, name: 'Yoga'},
  {id: 8, name: 'Stretching & Foam Rolling'},
  {id: 9, name: 'Breath Work'},
  {id: 10, name: 'Mindfulness'}
]

export const levelList = [
  {id: 0, name: 'All Levels'},
  {id: 1, name: 'Advanced'},
]

export const accessList = [
  {id: 1, name: 'Editor'},
  {id: 2, name: 'Admin'},
]

export const durationCategoryList = [
  {id: 0, name: 'Under 20 min'},
  {id: 1, name: '20 min to 30 min'},
  {id: 2, name: 'More than 30 min'},
]

export const sentimentList = [
  {id: 'POSITIVE', name: 'Positive'},
  {id: 'NEGATIVE', name: 'Negative'},
  {id: 'MIXED', name: 'Mixed'},
  {id: 'NEUTRAL', name: 'Neutral'},
]

export const exerciseList = [
  {id: 0, name: 'Arm Circles'},
  {id: 1, name: 'Around-the-World Lunges'},
  {id: 2, name: 'Bicep Curls'},
  {id: 3, name: 'Cycle'},
  {id: 4, name: 'Bulgarian Split Squat Jumps'},
  {id: 5, name: 'Bulgarian Split Squats'},
  {id: 6, name: 'Burpees'},
  {id: 7, name: 'Butt Kicks'},
  {id: 8, name: 'Calf Raises'},
  {id: 9, name: 'Cool Down'},
  {id: 10, name: 'Crunches'},
  {id: 11, name: 'Curl-to-Presses'},
  {id: 12, name: 'Deadlifts (Dumbell)'},
  {id: 13, name: 'Flutter Kicks'},
  {id: 14, name: 'Frog Jumps'},
  {id: 15, name: 'Glute Bridges'},
  {id: 16, name: 'Inchworms '},
  {id: 17, name: 'Instruction'},
  {id: 18, name: 'Introduction'},
  {id: 19, name: 'Jumping Jacks'},
  {id: 20, name: 'Leg Lifts'},
  {id: 21, name: 'Lunges'},
  {id: 22, name: 'Lunge Jumps'},
  {id: 23, name: 'Mountain Climbers'},
  {id: 24, name: 'Low Plank'},
  {id: 25, name: 'Plank Jacks'},
  {id: 26, name: 'Plank Shoulder Taps'},
  {id: 29, name: 'Pop Squats'},
  {id: 30, name: 'Push-Ups'},
  {id: 31, name: 'Quick Feet'},
  {id: 32, name: 'Renegade Rows'},
  {id: 33, name: 'Reverse Fly'},
  {id: 34, name: 'Reverse Lunges'},
  {id: 35, name: 'Run'},
  {id: 36, name: 'Russian Twists'},
  {id: 37, name: 'Scissor Kicks'},
  {id: 38, name: 'Shoulder Presses (alt)'},
  {id: 39, name: 'Side Plank'},
  {id: 40, name: 'Single-Leg Deadlifts'},
  {id: 41, name: 'Sit-Up Presses'},
  {id: 42, name: 'Squats'},
  {id: 43, name: 'Standing Rows'},
  {id: 44, name: 'Step-Ups'},
  {id: 45, name: 'Sumo Squats'},
  {id: 46, name: 'Superman'},
  {id: 47, name: 'T Push-Ups'},
  {id: 48, name: 'Tension Curls'},
  {id: 49, name: 'Transition'},
  {id: 50, name: 'Tricep Extensions'},
  {id: 51, name: 'Tricep Dips'},
  {id: 52, name: 'Tuck Jumps'},
  {id: 53, name: 'Wall Sit'},
  {id: 54, name: 'Jog'},
  {id: 55, name: 'Sprint'},
  {id: 56, name: 'Bicycle Crunches'},
  {id: 57, name: 'Plank Dips'},
  {id: 58, name: 'Rows'},
  {id: 59, name: 'Lateral-to-Front Raises'},
  {id: 60, name: 'Sprawls'},
  {id: 61, name: 'Plank-Ups'},
  {id: 62, name: 'Pistol Squats'},
  {id: 63, name: 'Squat Presses'},
  {id: 64, name: 'Kettlebell Swings'},
  {id: 65, name: 'High Pulls'},
  {id: 66, name: 'Rest'},
  {id: 67, name: 'Squat Jumps'},
  {id: 68, name: 'Sit-Ups'},
  {id: 69, name: 'Hop Up, Run Back'},
  {id: 70, name: 'Squat Lunges'},
  {id: 71, name: 'Squat-with-Steps'},
  {id: 72, name: 'Reverse Push-Ups'},
  {id: 73, name: 'Box Jumps'},
  {id: 75, name: 'Toe Taps'},
  {id: 76, name: 'Jump-Overs'},
  {id: 77, name: 'Split Squats'},
  {id: 78, name: 'High Plank'},
  {id: 81, name: 'Oblique Heel Touches'},
  {id: 82, name: 'Rock'},
  {id: 83, name: 'Single-Leg Sit-Ups'},
  {id: 85, name: 'Deadlift Front Squats'},
  {id: 86, name: 'Alternating Front Kicks (bodyweight)'},
  {id: 87, name: 'Alternating legs jumping rope'},
  {id: 88, name: 'Mini bands arm raises '},
  {id: 89, name: 'Bear Crawls'},
  {id: 90, name: 'KB: Clean'},
  {id: 91, name: 'KB: Clean & Press'},
  {id: 92, name: 'KB: Clean & Squat'},
  {id: 93, name: 'KB: Clean & Squat to Press'},
  {id: 94, name: 'Cross'},
  {id: 95, name: 'Donkey Kicks'},
  {id: 96, name: 'Double-Under Jump Rope'},
  {id: 97, name: 'Duck Jab Cross'},
  {id: 98, name: 'KB: Core Figure 8'},
  {id: 99, name: 'KB: Goblet Squat'},
  {id: 100, name: 'Ball: Hamstring Roll In'},
  {id: 101, name: 'High Knees'},
  {id: 102, name: 'High-Knee Jump Rope'},
  {id: 103, name: 'Glute Bridges: Mini Bands'},
  {id: 104, name: 'Glute Bridges: Ball'},
  {id: 105, name: 'Hook'},
  {id: 106, name: 'Jab'},
  {id: 107, name: 'Jab Cross'},
  {id: 108, name: 'Jump Rope'},
  {id: 109, name: 'Knee Touches (Sliders)'},
  {id: 110, name: 'Knee Tucks (Sliders)'},
  {id: 111, name: 'Lateral Walks (Bands)'},
  {id: 112, name: 'Leg Lifts (Ball)'},
  {id: 113, name: 'Single Glute Bridge (Bands)'},
  {id: 114, name: 'KB: Lunge Passes'},
  {id: 115, name: 'Modified High Plank'},
  {id: 116, name: 'Modified Low Plank'},
  {id: 117, name: 'Modified Shoulder Taps'},
  {id: 118, name: 'Modified Side Plank'},
  {id: 119, name: 'Plank Drag (Kettlebell)'},
  {id: 120, name: 'Plank Steps (Bands)'},
  {id: 121, name: 'Plank (Ball)'},
  {id: 122, name: 'Mountain Climbers (Ball)'},
  {id: 123, name: 'Plank Roll-Ups (Ball)'},
  {id: 124, name: 'Plank Steps (Ball)'},
  {id: 125, name: 'Plank (Bands)'},
  {id: 126, name: 'Reverse Lunge Front Kicks'},
  {id: 127, name: 'Reverse Lunges with Curl'},
  {id: 128, name: 'Reverse Lunges with Press'},
  {id: 129, name: 'Reverse Lunges (Sliders)'},
  {id: 130, name: 'Reverse Lunges with Twist'},
  {id: 131, name: 'Samurai'},
  {id: 132, name: 'Side Lunges with Kick'},
  {id: 133, name: 'Side Lunges (Band)'},
  {id: 134, name: 'Plank Arm Circles (Sliders)'},
  {id: 135, name: 'Single-Arm Shoulder Presses'},
  {id: 136, name: 'KB: Single-Arm Swings'},
  {id: 137, name: 'Single-Leg Deadlifts (Sliders)'},
  {id: 138, name: 'Single-Leg Jump Rope'},
  {id: 139, name: 'Sit Up & Twist (Ball)'},
  {id: 140, name: 'Sit-Ups (Ball)'},
  {id: 141, name: 'Slip Slip'},
  {id: 142, name: 'KB: Snatch'},
  {id: 143, name: 'Squat with Hook'},
  {id: 144, name: 'Squat with Jab Cross'},
  {id: 145, name: 'Squats with High Pull'},
  {id: 146, name: 'Squats (Band)'},
  {id: 147, name: 'Step Outs Jab Cross'},
  {id: 148, name: 'Step Outs (Band)'},
  {id: 149, name: 'Crunch Toe Touches'},
  {id: 150, name: 'Up & Back Walks (Band)'},
  {id: 151, name: 'Upper Cut'},
  {id: 152, name: 'Weighted Lunges'},
  {id: 153, name: 'Weighted Side Lunges'},
  {id: 154, name: 'Weighted Sit-Ups (Ball)'},
  {id: 155, name: 'Weighted Squats'},
  {id: 156, name: 'Stretching'},
  {id: 157, name: 'Weighted Sumo Squats'},
  {id: 158, name: 'Mountain Climbers (Sliders)'},
  {id: 159, name: 'Deadlifts (Kettlebell)'},
  {id: 160, name: 'Yoga'},
  {id: 161, name: 'Stretching & Foam Rolling'},
  {id: 162, name: 'Breath Work'},
  {id: 163, name: '90-90'},
  {id: 164, name: '90-90 - Pigeon'},
  {id: 165, name: '90-90 - Windshield Wiper'},
  {id: 166, name: 'All Fours - Full Wrist Stretch'},
  {id: 167, name: 'All Fours - Half Wrist Stretch'},
  {id: 168, name: 'All Fours - Reach Back'},
  {id: 169, name: 'All Fours - Twist Down'},
  {id: 170, name: 'All Fours - Twist Up'},
  {id: 171, name: 'All Fours - Twist Up - Hand behind head'},
  {id: 172, name: 'Archer'},
  {id: 173, name: 'Archer - Strap'},
  {id: 174, name: 'Arm Circles - Backward'},
  {id: 175, name: 'Arm Circles - Forward'},
  {id: 176, name: 'Banana'},
  {id: 177, name: 'Bent Knee Twist'},
  {id: 178, name: 'Bent Knee Twist - Alternating'},
  {id: 179, name: 'Breathing'},
  {id: 180, name: 'Breathing - Reclined'},
  {id: 181, name: 'Bridge'},
  {id: 182, name: 'Bridge - Supported'},
  {id: 183, name: 'Butterfly'},
  {id: 184, name: 'Butterfly - Extended'},
  {id: 185, name: 'Butterfly - Reclined'},
  {id: 186, name: 'Calf Stretch - Standing'},
  {id: 187, name: 'Calf Stretch - Wall'},
  {id: 188, name: 'Calves - Double & Single'},
  {id: 189, name: 'Camel'},
  {id: 190, name: 'Cat'},
  {id: 191, name: 'Cat v2'},
  {id: 192, name: 'Cat - Standing'},
  {id: 193, name: 'Cat & Cow'},
  {id: 194, name: 'Chest Stretch - Prone'},
  {id: 195, name: 'Chest Stretch - Wall'},
  {id: 196, name: 'Childs Pose'},
  {id: 197, name: 'Chin Drop'},
  {id: 198, name: 'Chin Raise'},
  {id: 199, name: 'Circles - Hip'},
  {id: 200, name: 'Circles - Neck'},
  {id: 201, name: 'Circles - Wrist'},
  {id: 202, name: 'Cobra'},
  {id: 203, name: 'Cow'},
  {id: 204, name: 'Cow - Standing'},
  {id: 205, name: 'Cross Arms'},
  {id: 206, name: 'Down Dog'},
  {id: 207, name: 'Down Dog - Calf Stretch'},
  {id: 208, name: 'Down Dog - Open Hip'},
  {id: 209, name: 'Down Dog - Peddle Feet'},
  {id: 210, name: 'Down Dog - Three Leg'},
  {id: 211, name: 'Eagle - Bind'},
  {id: 212, name: 'Eagle - Cross Arms'},
  {id: 213, name: 'Eagle - Progression'},
  {id: 214, name: 'Figure Four'},
  {id: 215, name: 'Figure Four - Standing'},
  {id: 216, name: 'Figure Four - Standing Reach'},
  {id: 217, name: 'Figure Four - Wall'},
  {id: 218, name: 'Firelog'},
  {id: 219, name: 'Forward Fold - Cross-Legged'},
  {id: 220, name: 'Forward Fold - Front Strap'},
  {id: 221, name: 'Forward Fold - Single Leg'},
  {id: 222, name: 'Forward Fold - Standing'},
  {id: 223, name: 'Full Body Stretch - Reclined'},
  {id: 224, name: 'Gate'},
  {id: 225, name: 'Glute - Single'},
  {id: 226, name: 'Half Split'},
  {id: 227, name: 'Half Split & Lunge'},
  {id: 228, name: 'Hamstrings - Double & Single'},
  {id: 229, name: 'Happy Baby'},
  {id: 230, name: 'High Lunge'},
  {id: 231, name: 'High Lunge - Bound Twist'},
  {id: 232, name: 'High Lunge - Twist'},
  {id: 233, name: 'Hips Circles - Back'},
  {id: 234, name: 'Hips Circles - Forward'},
  {id: 235, name: 'Horse - In & Out'},
  {id: 236, name: 'Horse - Shoulder Drop'},
  {id: 237, name: 'Inchworm'},
  {id: 238, name: 'Inner Thigh'},
  {id: 239, name: 'Interlaced Hands - Press Overhead'},
  {id: 240, name: 'Interlaced Hands - Side Bend'},
  {id: 241, name: 'IT Band'},
  {id: 242, name: 'IT Stretch - Reclined'},
  {id: 243, name: 'Knee to Chest - Block'},
  {id: 244, name: 'Knee to Chest - Reclined'},
  {id: 245, name: 'Knee to Chest - Standing'},
  {id: 246, name: 'Knees to Chest - Reclined'},
  {id: 247, name: 'Knock Knees'},
  {id: 248, name: 'Lat'},
  {id: 249, name: 'Legs Overhead'},
  {id: 250, name: 'Legs Up The Wall'},
  {id: 251, name: 'Legs Up The Wall Split'},
  {id: 252, name: 'Locust & Bind'},
  {id: 253, name: 'Low Squat'},
  {id: 254, name: 'Lower Back - Center'},
  {id: 255, name: 'Lunge - High Quad'},
  {id: 256, name: 'Lunge - Lateral'},
  {id: 257, name: 'Lunge - Low'},
  {id: 258, name: 'Lunge - Low Hip Opener'},
  {id: 259, name: 'Lunge - Low Quad'},
  {id: 260, name: 'Lunge - Low Twist'},
  {id: 261, name: 'Lunge - Twist'},
  {id: 262, name: 'Meditation'},
  {id: 263, name: 'Neck Side Stretch'},
  {id: 264, name: 'Pigeon'},
  {id: 265, name: 'Puppy'},
  {id: 266, name: 'Pyramid'},
  {id: 267, name: 'Quad Bridge'},
  {id: 268, name: 'Quad Stretch - Side'},
  {id: 269, name: 'Quad Stretch - Standing'},
  {id: 270, name: 'Quad Stretch At The Wall'},
  {id: 271, name: 'Reclined Hamstring'},
  {id: 272, name: 'Resting - Reclined'},
  {id: 273, name: 'Rock the Cradle'},
  {id: 274, name: 'Seated Cat & Cow'},
  {id: 275, name: 'Seated Cross-Legged Fold'},
  {id: 276, name: 'Seated Figure Four'},
  {id: 277, name: 'Seated Knee Bend Twist'},
  {id: 278, name: 'Shin Stretch'},
  {id: 279, name: 'Shoulder Roll - Backward'},
  {id: 280, name: 'Shoulder Roll - Forward'},
  {id: 281, name: 'Shoulder Shrugs'},
  {id: 282, name: 'Shoulder Stretch - Wall'},
  {id: 283, name: 'Side Bend - Alternating'},
  {id: 284, name: 'Side Bend - Cross-Legged'},
  {id: 285, name: 'Side Bend - Seated'},
  {id: 286, name: 'Side Bend - Standing'},
  {id: 287, name: 'Side Lunge - Alternating'},
  {id: 288, name: 'Side Squat'},
  {id: 289, name: 'Slow Deep Squats'},
  {id: 290, name: 'Standing Side Bend Alternating'},
  {id: 291, name: 'Standing Strap Twist'},
  {id: 292, name: 'Star'},
  {id: 293, name: 'Straddle Fold - Arms Over'},
  {id: 294, name: 'Straddle Fold - Standing'},
  {id: 295, name: 'Straddle Fold - Standing Single Leg'},
  {id: 296, name: 'Straddle Fold - Twist'},
  {id: 297, name: 'Straddle Side Bend'},
  {id: 298, name: 'Triangle - Reverse'},
  {id: 299, name: 'Triangle - Standing'},
  {id: 300, name: 'Tricep Stretch'},
  {id: 301, name: 'Twist - Reclined'},
  {id: 302, name: 'Twist - Seated'},
  {id: 303, name: 'Twist - Standing'},
  {id: 304, name: 'Upward Dog'},
  {id: 305, name: 'Upward Dog v2'},
  {id: 306, name: 'Upward Dog & Shoulder Dip'},
  {id: 307, name: 'Vertical Legs'},
  {id: 308, name: 'Windshield Wiper Legs'},
  {id: 309, name: 'Wrist Stretch'}
]

export const fitnessGoalList = [
  {id: 0, name: 'Lose weight'},
  {id: 1, name: 'Get stronger'},
  {id: 2, name: 'Cross train'},
  {id: 3, name: 'Run faster'},
  {id: 4, name: 'Save time'},
  {id: 5, name: 'Try something new'},
  {id: 6, name: 'Workout at home'},
  {id: 7, name: 'Workout at the gym'},
]

export const exerciseListSorted = exerciseList.sort(compare)

function compare (a, b) {
  if (a.name < b.name)
    return -1
  if (a.name > b.name)
    return 1
  return 0
}
