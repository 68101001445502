import React from 'react'

import {
  Datagrid,
  List,
  DateField,
  ReferenceField,
  TextField
} from 'react-admin'


import Icon from '@material-ui/icons/DateRange'

export const scheduledReleaseIcon = Icon

export const scheduledReleaseList = (props) => (
  <List {...props} title="Scheduled Releases"  sort={{field: 'releaseDate', order: 'ASC'}} bulkActionButtons={false} perPage={50}>
    <Datagrid>

      <ReferenceField source="classId" reference="class">
        <TextField source="name"/>
      </ReferenceField>

        <DateField showTime source="releaseDate" />
    </Datagrid>
  </List>
)
