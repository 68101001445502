import React from 'react';
import {TopToolbar} from 'react-admin';
import DownloadButton from "./DownloadButton";

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const PromotionEditActions = ({ resource, basePath, data, hasDelete, hasShow, refresh }) => {
    if(data && (data.promotionType === 0 || data.promotionType === 2)){
       return ( <TopToolbar style={cardActionStyle}>
            <DownloadButton record={data}/>
        </TopToolbar>)
    } else {
       return  (<TopToolbar style={cardActionStyle}>
        </TopToolbar>)
    }
};

export default PromotionEditActions;
