import React from 'react'

import {
  Create,
  Datagrid,
  Edit,
  ImageField,
  List,
  maxLength,
  required,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
  TopToolbar,
  ListButton,
  RefreshButton
} from 'react-admin'

import Icon from '@material-ui/icons/Person'
import {
  trainerLocationList,
} from '../enumLists'
import {ListPagination} from '../Utilities/transformations'

import { withStyles } from '@material-ui/core/styles'

export const InstructorIcon = Icon

const FullNameField = ({record = {}}) => <span>{record.firstName} {record.lastName}</span>
FullNameField.defaultProps = {label: 'Name'}

//Instructor
const InstructorTitle = ({record}) => {
  return <span>Edit {record ? `"${record.firstName}"` : ''}</span>
}

const styles = {
  first_name: { display: 'inline-block' },
  last_name: { display: 'inline-block', marginLeft: 32 },
};

const InstructorEditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

export const InstructorList = (props) => (
  <List title="Instructor" {...props} bulkActionButtons={false} perPage={50} pagination={<ListPagination/>} sort={{field: 'id', order: 'ASC'}}>
    <Datagrid rowClick="edit">
      <TextField source="id"/>
      <FullNameField source="firstName"/>
    </Datagrid>
  </List>
)

export const InstructorEdit =  withStyles(styles)(({classes,  ...props }) => (
    <Edit title={<InstructorTitle/>} actions={<InstructorEditActions />}  { ...props}>
      <SimpleForm >

        <TextInput source="id" disabled/>
        <TextInput source="firstName" label="First Name"
                   validate={[required()]}
                   formClassName={classes.first_name}
        />
        <TextInput source="lastName" label="Last Name"
                   validate={[required()]}
                   formClassName={classes.last_name}
        />
        <SelectInput source="location"
                     choices={trainerLocationList}/>
        <TextInput source="bio" multiline fullWidth
                       validate={[required(), maxLength(500, 'Max length is 500')]}/>
        <TextInput source="lifePhilosophy" label="Life Philosophy" multiline fullWidth
                       validate={[required(), maxLength(250, 'Max length is 250')]}/>
        <TextInput source="itFactor" label="IT-Factor" multiline fullWidth
                       validate={[required(), maxLength(250, 'Max length is 250')]}/>
        <TextInput source="photoUrl" label="Instructor Image" multiline fullWidth
                       validate={[required(), maxLength(250, 'Max length is 250')]}/>
        <ImageField source="photoUrl" title="Image Preview"/>
      </SimpleForm>
    </Edit>
))

export const InstructorCreate = withStyles(styles)(({classes,  ...props }) => (
  <Create  {...props}>
    <SimpleForm>
      <TextInput source="firstName" label="First Name"
                 validate={[required()]}
                 formClassName={classes.first_name}
      />
      <TextInput source="lastName" label="Last Name"
                 validate={[required()]}
                 formClassName={classes.last_name}
      />
      <SelectInput source="location"
                   choices={trainerLocationList}/>
      <TextInput source="bio" multiline fullWidth
                     validate={[required(), maxLength(500, 'Max length is 500')]}/>
      <TextInput source="lifePhilosophy" label="Life Philosophy" multiline fullWidth
                     validate={[required(), maxLength(250, 'Max length is 250')]}/>
      <TextInput source="itFactor" label="IT-Factor" multiline fullWidth
                     validate={[required(), maxLength(250, 'Max length is 250')]}/>
      <TextInput source="photoUrl" label="Instructor Image" multiline fullWidth
                     validate={[required(), maxLength(250, 'Max length is 250')]}/>
      <ImageField source="photoUrl" title="Image Preview"/>
    </SimpleForm>
  </Create>
))
