import React, { Children, cloneElement, Component } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/RemoveCircleOutline'
import CopyIcon from '@material-ui/icons/FileCopy'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import DragHandle from '@material-ui/icons/DragHandle'
import Snackbar from '@material-ui/core/Snackbar';



import FormInput from './FormInput'
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc'

const styles = theme => ({
  drag: {
    cursor: 'row-resize',
  },
  root: {
    padding: 0,
    marginBottom: 20,
    '& > li:last-child': {
      borderBottom: 'none',
    },
  },
  line: {
    display: 'flex',
    listStyleType: 'none',
    borderBottom: `solid 1px ${theme.palette.divider}`,
    [theme.breakpoints.down('xs')]: {display: 'block'},
    '&.fade-enter': {
      opacity: 0.01,
      transform: 'translateX(100vw)',
    },
    '&.fade-enter-active': {
      opacity: 1,
      transform: 'translateX(0)',
      transition: 'all 500ms ease-in',
    },
    '&.fade-exit': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '&.fade-exit-active': {
      opacity: 0.01,
      transform: 'translateX(100vw)',
      transition: 'all 500ms ease-in',
    },
  },
  index: {
    width: '3em',
    paddingTop: '1em',
    [theme.breakpoints.down('sm')]: {display: 'none'},
  },
  form: {
    flex: 2,
    display: 'flex'
  },
  action: {
    paddingTop: '0.5em',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },

})

const Dragger = SortableHandle(() => <DragHandle style={{cursor:'row-resize', display: 'inline-block',paddingRight:'15px',paddingTop:'20px', width: '20px'}}/>)

const SortableItem = withStyles(styles)(SortableElement(({props, classes, member, itemIndex, removeField, duplicateField}) => {
  const {
    basePath,
    children,
    record,
    resource,
  } = props

  return <li className={classes.line}>
    <Dragger/>
    <Typography
      variant="body1"
      className={classes.index}
    >
      {itemIndex}
    </Typography>
    <section className={classes.form}>
      {Children.map(children, input => {
        return (
          <div style={{display: 'inline-block', verticalAlign: 'top',paddingRight:'15px'}}>
          <FormInput
            basePath={basePath}
            input={cloneElement(input, {
              source: `${member}.${input.props.source}`,
              label:
              input.props.label ||
              input.props.source,
              // eslint-disable-next-line
              disabled: (record.workflowStep===1 && input.props.source==='duration' ||
                // eslint-disable-next-line
              record.workflowStep !== 1 && (input.props.source === 'endTimeMin' || input.props.source === 'endTimeSec'))

            })}
            record={record}
            resource={resource}
          />
          </div>
        )
      })}
    </section>
    <section style={{alignSelf:'flex-end',display:'flex'}}>
    <span className={classes.action}>
                                      <Button
                                        size="small"
                                        onClick={removeField(itemIndex)}
                                      >
                                          <CloseIcon
                                            className={classes.leftIcon}
                                          />
                                        remove
                                      </Button>
                                  </span>
    <span className={classes.action}>
                                      <Button
                                        size="small"
                                        onClick={duplicateField(itemIndex)}
                                      >
                                          <CopyIcon
                                            className={classes.leftIcon}
                                          />
                                        duplicate
                                      </Button>
                                  </span>
    </section>
  </li>
}))

const SortableList = withStyles(styles)(SortableContainer(({props, ids, classes, removeField, addField, duplicateField}) => {
  const {
    fields
  } = props

  return (
    <TransitionGroup>
      {fields.map((member, index) => {
        return (
          <CSSTransition
            key={ids[index]}
            timeout={500}
            classNames="fade"
          >
            <SortableItem props={props} classes={classes} member={member} itemIndex={index} index={index} duplicateField={duplicateField}
                          removeField={removeField}/>
          </CSSTransition>
        )
      })}
    </TransitionGroup>
  )
}))

export class SimpleFormIteratorCustom extends Component {
  constructor (props) {
    super(props)
    // we need a unique id for each field for a proper enter/exit animation
    // but redux-form doesn't provide one (cf https://github.com/erikras/redux-form/issues/2735)
    // so we keep an internal map between the field position and an autoincrement id
    this.nextId = 0
    this.ids = props.fields ? props.fields.map(() => this.nextId++) : []
  }
  state = {
    open: false,
  };

  removeField = index => () => {
    const {fields} = this.props

    this.ids.splice(index, 1)
    fields.remove(index)
  }

  duplicateField = index => () => {
    const {fields} = this.props
    let copiedItem = fields.get(index)
    let newItem = {
      'exercise': copiedItem.exercise,
      'duration': copiedItem.duration,
      'classId': copiedItem.classId,
      'incline': copiedItem.incline,
    }
    this.ids.push(this.nextId++)
    fields.push(newItem)

  }

  addField = () => {
    const {fields} = this.props
    this.ids.push(this.nextId++)
    fields.push({})
  }


  handleClose = (event, reason) => {
    this.setState({ open: false });
  };

  onSortEnd = ({oldIndex, newIndex}) => {
    if(this.props.record.workflowStep > 0){
      this.setState({ open: true });

    } else if(oldIndex !== newIndex) {
      this.props.fields.move(oldIndex, newIndex)
      arrayMove(this.ids, oldIndex, newIndex)
    }
  }

  render () {

    const {
      classes = {},
      meta: {error, submitFailed},
    } = this.props

    return <ul className={classes.root} style={{listStyleType:'none'}}>
      {submitFailed && error && <span>{error}</span>}

      <SortableList props={this.props} ids={this.ids} onSortEnd={this.onSortEnd} classes={classes}
                    duplicateField={this.duplicateField} removeField={this.removeField} useDragHandle={true} />
      <li className={classes.line}>

        <span className={classes.action}>
                        <Button size="small" onClick={this.addField}>
                            <AddIcon className={classes.leftIcon}/>
                          add
                        </Button>
                    </span>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}        ContentProps={{
          'aria-describedby': 'message-id',
        }}
          message={<span id="message-id">Move class to Edit to rearrange</span>}
          action={[

          ]}
        />
      </li>
    </ul>

  }
}

SimpleFormIteratorCustom.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  resource: PropTypes.string,
}

export default withStyles(styles)(SimpleFormIteratorCustom)
