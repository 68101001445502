import React, { Component } from 'react'
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux'

import { UPDATE } from 'react-admin'
import restClient from '../restClient'
import { showNotification as showNotificationAction } from 'react-admin'
import { refreshView as refreshViewAction } from 'react-admin';
import blueGrey from '@material-ui/core/colors/blueGrey'



class LogoutUserButton extends Component {
  handleClick = () => {
    const {  showNotification, record, refreshView} = this.props
    var result = window.confirm("This will force the user to re-authenticate");
    if(result) {
      restClient(UPDATE, 'user', {id: record.id + '/logout'}).then(() => {
        showNotification('User logged out');
        refreshView();
      }).catch((e) => {
        console.error(e)
        showNotification('Error: User not logged out', 'warning')
      })
    }
  }

  render () {
    return <Button onClick={this.handleClick} style={{color: blueGrey[500], alignItems:'center', marginRight:0, marginLeft:'auto'}}>
      Force Log User Out
    </Button>
  }
}

export default connect(null, {
  showNotification: showNotificationAction,
  refreshView: refreshViewAction
})(LogoutUserButton)
