import React from 'react'

import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  Create,
  SimpleForm,
  Edit,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  DateInput,
  BooleanField,
} from 'react-admin'
import UserEditActions from './UserEditActions'
import { dateTimeParser, intervalTimeFormatter, ListPagination } from '../Utilities/transformations'

import Icon from '@material-ui/icons/AccountCircle'

import { withStyles } from '@material-ui/core/styles/index'

import ReferenceField from 'ra-ui-materialui/esm/field/ReferenceField'
import StarRatingField from '../ratings/StarRatingField'
import DeletedWorkoutField from '../users/DeletedWorkoutField'

export const UserIcon = Icon

const styles = {
  date: {display: 'inline-block', width:100},
  logout_button: {backgroundColor: '#3e51b5', width: 132, display: 'block'},
  form: {width:'100%'}
}


const TimeField = ({source, record = {}}) => <span>{intervalTimeFormatter(Math.floor(record[source]))} min</span>
const CustomDateTimeField = ({source,record={}}) => <span>{dateTimeParser(record[source])}</span>

export const UserList = (props) => (
  <List title="Users" {...props} bulkActionButtons={false} filters={<UserFilter/>} perPage={50} pagination={<ListPagination/>} sort={{field: 'id', order: 'DESC'}}>
    <Datagrid rowClick="edit">
      <TextField source="userId"/>
      <TextField source="email"/>
      <DateField source="compExpiration" label="Comp Expiration"/>
      <DateField source="createdAt" label="Joined"/>
    </Datagrid>
  </List>
)

export const UserCreate = (props) => (
  <Create {...props} >
    <SimpleForm>
      <TextInput source="email" multiline label="Single or Multiple emails(comma separated)"/>
      <h4>Comp user</h4>
      <DateInput source="compExpiration" label="Comp Expiration(s)" options={{format: 'MM/DD/YYYY'}}/>
    </SimpleForm>
  </Create>
)
export const UserEdit = withStyles(styles)(({classes,  ...props }) => (
  <Edit {...props} undoable={false} actions={<UserEditActions/>}>
    <TabbedForm>
      <FormTab label="User Info" >
        <TextField source="email" label="Email" className={classes.form}/>
        <TextField source="userId" label="User ID"/>
        <DateInput source="compExpiration" label="Set Comp Expiration" options={{format: 'MM/DD/YYYY'}}/>
        <DateField source="createdAt" label="Join Date"
                   formClassName={classes.date}
        />
        <DateField source="lastLogoutTime" label="Last Logout Date"
                   formClassName={classes.date}
        />

      </FormTab>

      <FormTab label="Payments">
        <SimpleForm toolbar={<div/>} className={classes.form}>
          <ReferenceManyField label="" source="userId" target="userId" reference="payment/transaction" pagination="">
            <Datagrid  classes={{table:classes.form}}>
              <DateField source="transaction_date" sortable={false}/>
              <DateField source="expiration_date" sortable={false}/>
              <TextField source="product" sortable={false}/>
              <BooleanField source="trial" sortable={false}/>
              <TextField source="platform" sortable={false}/>
              <TextField source="transaction_type" sortable={false}/>
            </Datagrid>
          </ReferenceManyField>
        </SimpleForm>
      </FormTab>

      <FormTab label="Workouts" >
        <SimpleForm toolbar={<div/>} className={classes.form} >
          <ReferenceManyField label="" source="userId" target="userId" reference="workout" pagination={<ListPagination/>}>
            <Datagrid classes={{table:classes.form}}>
              <ReferenceField source="classId" reference="class" >
                <TextField source="name"/>
              </ReferenceField>
              <CustomDateTimeField source="startTime" />
              <TimeField source="timeCompleted" label="Duration"/>
              <DeletedWorkoutField source="deleted" label="Is deleted?"/>
            </Datagrid>
          </ReferenceManyField>
        </SimpleForm>
      </FormTab>
      <FormTab label="Ratings" >
        <SimpleForm toolbar={<div/>} className={classes.form}>
          <ReferenceManyField label="" source="userId" target="userId" reference="ratings" pagination={<ListPagination/>}>
            <Datagrid  classes={{table:classes.form}}>
              <ReferenceField source="classId" reference="class">
                <TextField source="name"/>
              </ReferenceField>
              <StarRatingField />
              <TextField source="comment"/>
              <TextField source="sentiment"/>
            </Datagrid>
          </ReferenceManyField>
        </SimpleForm>
      </FormTab>
    </TabbedForm>
  </Edit>
))

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email"/>
    <TextInput label="User ID" source="userId"/>

  </Filter>
)
