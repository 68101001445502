import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button';
import FileDownload from '@material-ui/icons/GetApp'
import properties from '../Resources/properties'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

var styles = function styles(theme) {
  return {

    desktopLink: {
      display: 'inline-flex',
      alignItems: 'center',
      color: '#3f51b5'
    },

  };
};


const AllClassesDownloadButton = ({
                        basePath = '',
                        className,
                        classes = {},
                        translate,
                        label = 'ra.action.export',
                        ...rest
                      }) => (

     <Button
      children=''
      type="submit"
      className={classnames(classes.desktopLink, className)}
      onClick={function () {
      return download()}}
      {...rest}
     >
      <FileDownload/>
      Export
    </Button>

);

function download () {
  const token = sessionStorage.getItem('token')

  let xhttp = new XMLHttpRequest()
  xhttp.open('GET', properties.host + '/class/print', true)
  xhttp.setRequestHeader('Authorization', 'bearer ' + token)
  xhttp.send()

  xhttp.onreadystatechange = function () {
    if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
      let data = xhttp.response
      const blob = new Blob([data], {type: 'text/csv'})
      const fileName = 'All_Classes.csv'
      const objectUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')

      a.href = objectUrl
      a.download = fileName
      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
      URL.revokeObjectURL(objectUrl)

    }
  }
}

AllClassesDownloadButton.propTypes = {
  record: PropTypes.object,
  reviewApprove: PropTypes.func,
  translate: PropTypes.func,
}

export default withStyles(styles)(AllClassesDownloadButton)
